<template>
  <div class="all-customers-page">

    <Alerta
      type="info"
      titulo="Novidade!"
      mensagem="Nova área para visualização de dados dos clientes."
      idMemoria="dicaCustomersNew3"
    />

    <!-- <div class="box-filter">
      <div></div>
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div> -->

    <b-row>
      <b-col md="12" lg="12" sm="12">

        <div class="primary">
          <div class="box table">
            <div class="box__header">
              <HeaderGraphic :data="loadTable" />
              <a v-if="loadTable.items.length > 0" class="btn-download" @click="downloadCSV()" download
                >Download CSV</a
              >
            </div>
            <div class="painel">
              <b-table :items="loadTable.items">
                <template #cell(nome)="data">
                  <a :href="`/customers/${data.item.Nome.id}/details`" class="h6 cor-escura">
                    <IconStar :active="(data.item.Nome.starred) ? data.item.Nome.starred : false" />
                    {{ data.item.Nome.text }}
                    <small v-if="data.item.Nome.storeUserId" class="cor-media">#{{ data.item.Nome.storeUserId }}</small>
                  </a>
                </template>
              </b-table>

              <paginate
                v-model="currentPage"
                :page-count="lastPage"
                :prev-text="'‹'"
                :next-text="'›'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapState } from "vuex";
import Alerta from "@/components/alerta/Alerta.vue";
// import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import IconStar from "@/components/icones/IconStar.vue";
import { numberToRealWitoutSymbol, numberToReal, numberWithK } from "@/utils/money";
import { filterData } from "@/utils/date";
import { setColor, setStatusDescription } from "@/utils/status";
import {
  fetchAllCustomers,
  fetchAllCustomersCSV,
} from "@/api/customers";

export default {
  filters: {
    numberToRealWitoutSymbol,
    numberToReal,
    numberWithK,
    filterData,
  },
  components: {
    HeaderGraphic,
    IconStar,
    // FilterDatepicker,
    Alerta,
  },
  data() {
    return {
      tableData: {},
      perPage: 10,
      currentPage: 1,
      start: null,
      end: null,
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.customers.list,
      lastPage: (state) => state.customers.list_last_page,
    }),
    loadTable() {
      const items = [];
      // console.log('loadTable', this.list)
      if (this.list.data && this.list.data.length > 0) {
        this.list.data.map((line) => {
          items.push(
            {
              Nome: {
                id: btoa(line.id),
                storeUserId: line.storeUserId,
                text: `${line.firstName} ${line.lastName}`,
                starred: line.starred
              },
              "Qtde. de pedidos": line.ordersCount,
              Receita: numberToReal(line.ordersRevenue),
            }
          );
        });
      }

      let data = {
        title: {
          top: "",
          name: (this.list.total) ? 'Meus Clientes ('+this.list.total+')' : 'Meus Clientes',
        },
        items,
      };
      return data;
    },
  },
  mounted() {
    this.loadPrincipal();
  },
  methods: {
    fetchAllCustomers,
    fetchAllCustomersCSV,
    setColor,
    setStatusDescription,
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      this.loadTab(this.tab);
    },
    downloadCSV() {
      this.fetchAllCustomersCSV(this.start, this.end);
    },
    loadPrincipal() {
      this.fetchAllCustomers(this.perPage, this.currentPage);
    },
  },
  watch: {
    // lastPage(data) {
    //   console.log('watch lastPage', data);
    // },
    currentPage(newPage) {
      this.currentPage = newPage;
      this.fetchAllCustomers(this.perPage, this.currentPage);
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .multiselect__placeholder {
    margin-top: 3px;
  }
}
.all-customers-page {
  .empty {
    text-align: center;
    width: 100%;
    font-size: 16px;
    height: 100%;
    padding: 90px 0;
    display: flex;
    color: $red !important;
    align-items: center;
    justify-content: center;
  }
  .primary {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    &.primary--mb {
      margin-bottom: 30px;
    }
    .btn-download {
      position: relative;
      z-index: 1;
    }
  }
  .box-filter {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 16px;
    &__tabs {
      display: flex;
      border-bottom: 1px solid #bababa;
      @media only screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
    &__tabs-item {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        text-align: center;
      }
      &:hover {
        color: $red;
      }
      &:last-child {
        margin-right: 0;
      }
      &.--active {
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: -1px;
          background: $red;
          @media only screen and (max-width: 991px) {
            left: 0;
          }
        }
      }
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 18px;
    box-shadow: 0 3px 6px #c3d0e029;
    border-radius: 20px;
    @media screen and (max-width: 991px) {
      gap: 20px;
      padding: 20px 15px;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    &--chart {
      flex-direction: column;
      height: 400px;
      .header-graphic {
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    .painel {
      position: relative;
      height: 300px;
      display: block;
      .table {
        th[aria-colindex="2"],
        td[aria-colindex="2"],
        th[aria-colindex="3"],
        td[aria-colindex="3"],
        th[aria-colindex="5"],
        td[aria-colindex="5"],
        th[aria-colindex="6"],
        td[aria-colindex="6"] {
          white-space: nowrap;
          text-align: right;
        }
        th[aria-colindex="4"],
        td[aria-colindex="4"] {
          white-space: nowrap;
          text-align: center;
        }
      }
    }
    &.table {
      margin: 0;
      flex-direction: column;
      height: auto;
      &:after {
        content: "";
        display: block;
      }
      .painel {
        height: auto; 
      }
    }
    .panel {
      &__content {
        padding: 0;
        background: none;
        box-shadow: none;
      }
      &__header {
        @media screen and (max-width: 991px) {
          min-height: 1010px;
        }
        @media screen and (max-width: 600px) {
          min-height: 60px;
        }
      }
    }

    .number {
      &--medium {
        font-size: 50px;
        @media screen and (max-width: 991px) {
          font-size: 25px;
        }
        @media screen and (max-width: 600px) {
          font-size: 35px;
        }
      }
    }
  }

  .v-table {
    display: inline-block;
    width: 100%;
    margin-top: 35px;
    &__row {
      display: grid;
      grid-template-columns: 0.1fr 1.3fr 0.3fr 0.5fr;
      align-items: center;
      margin-top: 0.9rem;
      gap: 15px;
      padding-bottom: 18px;
      border-bottom: 1px solid #e6e6e6;
      font-weight: bold;
      color: #2c2c2c;
      font-size: 16px;
      &:last-child {
        border-bottom: none;
      }
    }
    &__cell {
      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
